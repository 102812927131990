<template>
  <!--	<meta name="viewport" content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover">-->
  <div>
    <rxNavBar title="账单" androidOrIOSFlag="true"></rxNavBar>
    <!-- 顶部固定区域-->
    <div class="header">
      <!-- 预约切换区域-->
      <div class="choose">
        <div class="choose-one">
          <div :class="condition?'choose-one-textActive':'choose-one-text'" @click="showOnOrder">租客</div>
          <div class="choose-one-icon" v-show="condition"></div>
        </div>
        <div class="choose-line"></div>
        <div class="choose-one">
          <div :class="!condition?'choose-one-textActive':'choose-one-text'" @click="showFinished">业主</div>
          <div class="choose-one-icon" v-show="!condition"></div>
        </div>
      </div>
    </div>
    <!-- 显示一共几笔账单 -->
    <div class="totalBill">
      <span>共有{{useList.length}}笔待支付账单</span>
      <span class="viewRecord" @click="viewRecord">查看历史交易记录</span>
    </div>
    <!-- 账单列表 -->
    <div class="billList">
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <div class="billInfo" v-for=" (item,i) in useList " :key="i">
          <van-checkbox ref="checks" :name="item.bill_id" checked-color="#ffa65c" shape="square" icon-size="15px" @click="billAction(item.money,item.billBeginTime,item.bill_id,item)">
            <div class="left">
              <!-- 费用详情 -->
              <div class="detail">
                <span style="font-weight: 800;">{{item.feeType}}</span>
                -
                <span style="">{{item.roomAddress}}</span>
                -
                <span style="">{{item.roomName}}间</span>
              </div>
              <!-- 应缴时间 -->
              <div class="time">应缴时间：{{item.feeTime}}</div>
              <div class="time">费用周期：{{item.billBeginTime}} - {{item.billEndTime}}</div>
              <div class="time">描述：{{item.billDescription}}</div>
            </div>
            <div class="right">
              <div class="money">{{item.money}}元</div>
              <div class="overdue">{{item.statusLabel}}</div>
            </div>
          </van-checkbox>
        </div>
    <!-- 加载更多 -->
    <div class="load" v-if="display == true">
      <div @click="showAllOwner">加载更多未来账单</div>
      <img src="../../../assets/images/showmore.png" alt="">
    </div>
      </van-checkbox-group>

    </div>

    <!-- 最底部 -->

    <div class="payment">
      <div class="receipt" @click="receipt">
        <img src="../../../assets/images/receipt.png" alt="" >
        <div>如何开发票</div>
        </div>
<div class="paymentbutton">
      <van-checkbox class="btomcheck" type="primary" @click="ifcheck" v-model="checked" checked-color="#ffa65c" shape="square"
                    icon-size="15px">全选</van-checkbox>
      <!--			<div class="totalMoney">-->
  <div style="display: flex;align-items: center">
      <div style="color: rgba(102, 102, 102, 1);font-size: 13px;margin-right: 10px;">总计&nbsp;</div>
      <div style="color: rgba(255, 93, 59, 1);font-size: 16px;font-family: Oswald-Regular;font-weight: 500;margin-right: 10px">¥{{sum}}</div>
      <!--			</div>-->
      <div class="bottomNavigationB" @click="payment">立即支付</div>
  </div>
</div>
    </div>

    <!--		<div class="iphoneXBottom"></div>-->
    <!-- 没有数据时显示 -->
    <div class="empty" v-if="useList== []">
      <img src="../../../assets/images/empty.png" alt="">
      <div class="empryText">啊哦，这里好像什么都没有</div>
    </div>
  </div>

</template>

<script>
import {
  NavBar,
  Picker,
  List,
  PullRefresh,
  Toast,
  Popup,
  Checkbox,
  CheckboxGroup
} from "vant";
Vue.use(Picker);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
import {
  queryBaseData,
  userBillList,
  billContinuity
} from "../../../getData/getData";
import {
  checkAndroid, checkIOS,openInWebview,
  getUserId,
  globaluserId,
  responseUtil
} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import Vue from 'vue';
import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile';

//************** 跳转原生立即支付页面 start ******************
function goBill(billIdList,totalMoney,billType) {
  window.himi.gotoPayBill(billIdList,totalMoney,billType);
}
//************** 跳转原生立即支付页面 end   ******************

export default {
  name: "myOrder",
  data() {
    return {
      result: [],
      show: false,
      showPicker: false,
      condition: true, //用来控制两种状态的切换条件
      isOccupationShow: false,
      loading: false,
      finished: false,
      certificateType: '',
      isOccupationShow: false,
      defaultIndex: '',
      swi: true,
      isLoading: false,
      totalPage: '',
      checked: false,
      currentList: [],
      futureList: [],
      useList: [],
      billOwnerType: '1', //账单类型(0 业主 1 租客 2 房屋)
      display: true,
      sum: 0,
      billId: [],
      billType: [],  //账单类型
      checkedBillList:[],   //选中的账单集合
      countBillList:'',
      countBillListgroupby:[],
      disabled:true,
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    dialMobile,
    rxNavBar

  },
  created() {
    this.initData();
    this.userBillList();
    // this.initDicData();
  },
  //初始化
  mounted() {},
  methods: {
    //如何开发票
    receipt(){
      this.$router.push({
        name: 'receipt',
        query: {
          billOwnerType:this.billOwnerType
        }
      });
    },
    //初始化数据
    initData() {
      const that = this
      let initData = {
        user_id: globaluserId()
      }
    },
    //用户账单列表接口
    userBillList() {
      let that = this
      let initData = {};
      initData.user_id = globaluserId(); //用户id
      initData.billOwnerType = that.billOwnerType;
      userBillList(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          that.currentList = response.data.data.currentList;
          that.futureList = response.data.data.futureList;
          console.log(response.data.data)
          that.useList = that.currentList;
          that.countBillList=response.data.data.countBillList
          that.countBillListgroupby=response.data.data.countBillListgroupby
        })
      })
    },
    showAllOwner() {
      let that = this;
      if (that.futureList.length == that.currentList.length) {
        Toast('没有更多账单了')
        that.display = false
      } else {
        that.useList = that.futureList;
        that.display = false
      }
    },
    billAction(e,time,id,item) {

      let checkList = this.$refs.checks
      var num = 0
      this.sum = 0;
      this.billId = [];
      this.billType = [];
      this.checkedBillList=[]
      //debugger
      //添加选择某一个账单时，该账单费用周期前的账单同时被勾选
      let str = this.result.join("");//将选中的id集合转为string
      let s = id.toString()
      //判断是否选中
      if(str.indexOf(s) != -1){
        //循环账单集合取出时间小于点击账单的id使其选中
        for (let i = 0; i < this.useList.length; i++) {
          if(this.useList[i].billBeginTime <= time){
            this.result.push(this.useList[i].bill_id);
            this.result = this.unique(this.result)

          }
        }
      }

      let a = this.useList
      let b = this.result
      for (let j = 0; j < a.length; j++) {

        for (let jj = 0; jj < b.length; jj++) {
          if( b[jj] == a[j].bill_id){

            let checkedMoney = this.useList[j].money;
            let billId = this.useList[j].bill_id;
            let billTypeId = this.useList[j].billFeeType_id;
            num += Number(checkedMoney);
            this.sum = num.toFixed(2);
            this.billId.push(billId);
            this.billType.push(billTypeId);
            this.checkedBillList.push(this.useList[j])

          }
        }
      }



      // for (let j = 0; j < checkList.length; j++) {
      // 	//debugger
      // 	let checkedBill = checkList[j].checked;
      // 	if (checkedBill === true) {
      // 		let checkedMoney = this.useList[j].money;
      // 		let billId = this.useList[j].bill_id;
      // 		let billTypeId = this.useList[j].billFeeType_id;
      // 		num += Number(checkedMoney);

      // 		this.sum = num.toFixed(2);
      // 		this.billId.push(billId);
      // 		this.billType.push(billTypeId);
      // 	}

      // }


      console.log(this.billId)

    },
    ifcheck() {
      let that = this
      if (that.checked == true) {
        that.checkAll();
      }
      if (that.checked == false) {
        that.toggleAll();
      }
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
      this.sum = 0;
      var num = 0
      this.billId = [];
      this.billType = [];
      this.checkedBillList=[]
      for (let i = 0; i < this.useList.length; i++) {
        let checkedMoney = this.useList[i].money;
        let billId = this.useList[i].bill_id;
        let billTypeId = this.useList[i].billFeeType_id;
        num += Number(checkedMoney);
        this.sum = num.toFixed(2);
        this.billId.push(billId);
        this.billType.push(billTypeId);
        this.checkedBillList.push(this.useList[i])
      }
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
      this.sum = 0;
      this.billId = [];
    },

    showPopup() {
      this.show = true;
    },
    //租客账单
    showOnOrder() {
      var that = this
      that.default();
      that.condition = true
      this.billOwnerType = "1"
      this.userBillList();
    },
    //业主账单
    showFinished() {
      var that = this
      that.default();
      that.condition = false
      this.billOwnerType = "0"
      this.userBillList();
    },
    //返回上一页
    leftReturn() {
      this.$router.go(-1)
    },
    //分页
    onLoad() {
      this.userLeaseChangeList();
    },
    default () { //默认值
      this.useList = [];
      this.display = true;
      this.result = [];
      this.checked = false;
      this.sum = 0;
    },
    payment() {
      if(this.disabled){
        let billIdList = this.billId.toString()
        let totalMoney = this.sum;
        let billType = this.unique(this.billType).toString();
        if (this.sum == 0) {
          Toast('请选择账单')
        } else {
          var that=this
          var initData={}
          initData.futureList=this.futureList
          initData.selectSetList=this.checkedBillList
          //initData.countBillList=this.countBillList
          //initData.countBillListgroupby=this.countBillListgroupby
          initData.user_id=globaluserId()
          initData.billOwnerType=this.billOwnerType
          // console.log(initData)
          that.disabled = false
          billContinuity(initData).then(function(response) {
            responseUtil.dealResponse(that, response, () => {
              if(response.data.code==0){
                if (openInWebview()) {   //手机打开页面
                  if(checkAndroid()){   //安卓手机
                    goBill(billIdList,totalMoney,billType)
                    setTimeout(()=>{
                      that.disabled = true
                    },5000)
                  }else if(checkIOS()){   //苹果手机
                    let billInfo = {
                      billIdList: billIdList,
                      billType: billType
                    }
                    window.webkit.messageHandlers.gotoPayBill.postMessage({body: billInfo});
                    setTimeout(()=>{
                      that.disabled = true
                    },5000)
                  }
                }
                // that.disabled = true   //电脑端打开页面放开限制
              }
            })
            if(response.data.code!=0){  //选择账单有误，放开限制
                that.disabled = true
            }

          })
        }
      }

    },

    //数组去重
    unique(arr){
      let tempTypeId = []
      for(let i=0,len=arr.length;i<len;i++){
        if(tempTypeId.indexOf(arr[i]) === -1){
          tempTypeId.push(arr[i]);
        }
      }
      return tempTypeId;
    },

    //查看历史交易记录
    viewRecord(){
      this.$router.push({
        name: 'historicalTransactions',
        query: {
          billOwnerType:this.billOwnerType
        }
      });
    }
  },

}
</script>

<style scoped>
/*body {*/
/*	padding-top: constant(safe-area-inset-top);*/
/*	padding-left: constant(safe-area-inset-left);*/
/*	padding-right: constant(safe-area-inset-right);*/
/*	!*padding-bottom: constant(safe-area-inset-bottom);*!*/
/*}*/
.navbar {
  background-color: white;
  border-bottom: 2px solid white;
}
.receipt{
  font-size: 13px;
  background-color: rgb(254, 254, 187);
  width: 100%;
  /*height: 25px;*/
  /* line-height: 50rpx; */
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 15px;
  color: #676767;
}
.receipt img{
  width: 13px;
  height: 13px;
  margin-right: 5px;
}
.header {
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 8;
  top: 52px;
  background-color: #f8f8f8;
}

.choose {
  width: 100%;
  height: auto;
  background-color: white;
  display: flex;
}

.choose-one {
  width: 187px;
  text-align: center;
}

.choose-one-textActive {
  width: 42px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
  margin-left: 72px;
  color: rgba(255, 93, 59, 1);
  text-align: center;
  font-family: PingFangSC-Semibold;
}

.choose-one-text {
  width: 42px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin-top: 24px;
  margin-left: 72px;
  color: rgba(153, 153, 153, 1);
  text-align: center;
  font-family: PingFangSC-Semibold;
}

.choose-one-icon {
  width: 20px;
  height: 4px;
  border-radius: 3px;
  background-color: rgba(255, 93, 59, 1);
  margin: 5px 0 15px 83px;
}

.choose-line {
  width: 2px;
  height: 17px;
  margin-top: 23px;
  background-color: rgba(239, 239, 239, 1);
}

.empty {
  margin-top: 250px;
  text-align: center;
}

.empty img {
  width: 85px;
}

.empryText {
  text-align: center;
  color: #ACACAC;
  color: rgba(172, 172, 172, 1);
  font-size: 12px;
  margin-top: 50px;
}

.totalBill {
  height: 25px;
  margin: 30px 15px 0 15px;
  font-weight: 600;
  font-size: 12px;
  color: #ff5d3b;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewRecord{
    color: black;
}

.billInfo {
  width: 90%;
  height: 135px;
  border-left: 4px solid #ffa65c;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 15px;
}

::v-deep .van-checkbox {
  margin-top: 7px;
  float: left;
  margin-left: 10px;
}

.detail {
  font-size: 14px;
  width: 200px;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  margin-top: 7px;
  margin-bottom: 5px;
}

.time {
  font-size: 12px;
}

.left {
  float: left;
  padding-left: 10px;
}

.right {
  float: right;
  position: absolute;
  margin-top: 7px;
  right: 35px;
}

.money {
  font-size: 15px;
  font-family: Oswald-Regular;
  margin-bottom: 5px;
  text-align: right;
}

.overdue {
  color: rgba(255, 93, 59, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: right;
}

.load {
  color: #ff5b39;
  text-align: center;
  font-size: 13px;
   margin-bottom: 15px;
  height: 100%;
  width: 100%;
  z-index: 0;
  /*position: fixed;*/
  overflow-x: hidden;
  /*margin-top: -90px;*/
}

.load img {
  width: 10px;
  height: 10px;
}

.payment {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.paymentbutton{
  background-color: #ffffff;
  /*height: 70px;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ebebeb;
  padding: 15px;
}
.btomcheck {
  font-size: 14px;
  /*position: absolute;*/
  /*top: 15px;*/
  /*left: 15px;*/
}

.totalMoney {
  /*position: absolute;*/
  float: right;
  /*width: 50px;*/
  margin-left: 50%;
  margin-right: 30px;
  /*margin-top: 15px;*/
  /*top: 10px;*/
}

.bottomNavigationB {
  /*position: absolute;*/
  /*right: 10px;*/
  /*top: 10px;*/
  /*float: right;*/
  height: 40px;
  width: 86px;
  font-size: 14px;
  align-items: center;
  border-radius: 7px;
  background: linear-gradient(to right, #FFC274, #FF5D3B);
  color: white;
  /*margin-left: 70%;*/
  line-height: 40px;
  box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
  font-family: PingFangSC-Semibold;
  text-align: center;
}

.billList {
  margin-bottom: 130px;
}


.iphoneXBottom {
  /*position: fixed;*/
  /*left: 0;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  /*height: env(safe-area-inset-bottom);*/
  /*background: #fff;*/
  /*z-index: 500;*/
}
</style>
